@import '../miu-colors';
@import '../miu-variables';

$column-header-height: 57px;
$column-body-padding-topbottom: 8px;

%b-column--material {
  border: 1px solid $border-color;
  background: var(--colors__white);
  box-shadow: $std-box-shadow;
}

%b-column__title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.005em;
  line-height: 40px;
  margin: 0px;
}

%b-column__footer {
  border-top: 1px solid $border-color;
  padding: $column-body-padding-topbottom 16px;
  text-align: right;
  background-color: var(--colors__white);
}

%b-column__footer--absolute {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: var(--colors__white);
}

.b-columns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .b-column {
    @extend %b-column--material;

    position: relative;
    z-index: 1;
    height: 100%;

    .b-column__header {
      height: $column-header-height;
      border-bottom: 1px solid $border-color;
      padding: 8px 16px;

      .b-column__title {
        @extend %b-column__title;
      }
    }

    .b-column__body {
      padding: 8px 16px;
    }

    .b-column__footer {
      @extend %b-column__footer;
    }

    .b-column__btn {
      position: absolute;
      bottom: 8px;
      right: 8px;
      z-index: 2;
      color: var(--colors__white) !important;
    }
  }
}
