%payout {
  &:before {
    content: '\F2D8'; // .mdi-hexagon
  }
}

%netloss {
  &:before {
    content: '\F2D9'; // .mdi-hexagon-outline;
  }
}

%peril-icons {
  &:before {
    content: '\F130'; // .mdi-checkbox-blank-circle-outline
  }

  &.EQ {
    &:before {
      content: '\F437'; // .mdi-radar
    }
  }

  &.FL {
    &:before {
      content: '\F58C'; // .mdi-water
    }
  }

  &.WS {
    &:before {
      content: '\F210'; // .mdi-fan
    }
  }

  &.CS {
    &:before {
      content: '\F241'; // .mdi-flash
    }
  }

  &.WT {
    &:before {
      content: '\F716'; // .mdi-snowflake
    }
  }

  &.TR {
    &:before {
      content: '\F026'; // .mdi-alert
    }
  }

  &.ID {
    &:before {
      content: '\F6EE'; // .mdi-medical-bag
    }
  }

  &.FR {
    &:before {
      content: '\F238'; // .mdi-fire
    }
  }

  &.IP {
    &:before {
      content: '\F6EE'; // .mdi-medical-bag
    }
  }
}

%status-icons {
  &:before {
    content: '\F130'; // .mdi-checkbox-blank-circle-outline
  }

  &.SUCCESS {
    &:before {
      content: '\F5e0'; // .mdi-check-circle
    }
  }

  &.WARNING {
    &:before {
      content: '\F028'; // .mdi-alert-circle
    }
  }

  &.ERROR {
    &:before {
      content: '\F159'; // .mdi-close-circle
    }
  }
}

.peril-icons {
  @extend %peril-icons;
}

.status-icons {
  @extend %status-icons;
}
