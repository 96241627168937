@use '@angular/material' as mat;
@import '~@mdi/font/scss/materialdesignicons';
@import './miu-mixins';
@import './miu-traits';

@include mat.core();
$rms-theme-primary: mat.define-palette($mat-primary);
$rms-theme-accent: mat.define-palette($mat-accent);
$rms-theme-warn: mat.define-palette($mat-warn);
$rms-theme: mat.define-light-theme($rms-theme-primary, $rms-theme-accent, $rms-theme-warn);

body {
  @include mat.all-component-themes($rms-theme);

  /* Material Button */
  .radius-button-column {
    display: flex;
    flex-flow: column nowrap;

    button.radius + button.radius {
      margin: 10px 0px 0px 0px !important;
    }
  }

  button.radius + button.radius {
    margin-left: 10px !important;
  }

  .mat-button,
  .mat-raised-button,
  .mat-flat-button,
  .mat-stroked-button {
    &.radius {
      @extend %radius-button;
      @extend %radius-secondary-button;

      &.radius-primary {
        @extend %radius-primary-button;
      }

      &.radius-danger {
        @extend %radius-danger-button;
      }

      &.radius-plain {
        @extend %radius-plain-button;
      }

      &.full-width {
        width: 100%;
      }
    }
  }

  /* Material Icon Button */
  .mat-icon-button {
    @include radius-icon-button-common(rms-blue);
  }

  /* Material Menu Item */
  .mat-menu-item {
    @extend %hover-bg;
  }

  /* Material List Item */
  .mat-list-item {
    @extend %hover-bg;
  }

  /* Material Mini Fab */
  .mat-mini-fab {
    &.mat-accent.radius-primary {
      @extend %radius-primary-fab;
    }
  }

  /* Material Accordion */
  .mat-accordion {
    .mat-expansion-panel:first-of-type,
    .mat-expansion-panel:last-of-type {
      border-radius: 0;
    }
  }

  /* Material Snackbar */
  .mat-snack-bar-container {
    @include radius-snackbar(info, rms-blue);
    @include radius-snackbar(warning, autumn-amber);
    @include radius-snackbar(error, ruby-red);
    @include radius-snackbar(success, grizzly-green);

    min-height: 48px;
    border-radius: 25px;
    padding: 0 var(--spacing__regular);

    .snackbar-wrapper {
      display: flex;
      align-items: center;
      height: 100%;

      .text {
        padding-left: var(--spacing__regular);
        flex: 1;
      }

      button.action.radius.radius-plain {
        background-color: transparent;
        text-decoration: underline;
        color: $brand;
      }

      .mat-icon-button {
        margin-left: auto;

        .mat-icon.close {
          color: var(--colors__gray-70);
        }
      }
    }
  }

  /* Material Expansion Panel */
  .mat-expansion-panel {
    border-radius: 0;
    box-shadow: none !important;

    &:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
      background-color: $hover-bg;
    }

    .mat-expansion-panel-header,
    .mat-expansion-panel-body {
      border-radius: 0;
    }

    &.mat-expanded {
      box-shadow: $std-box-shadow !important;

      &:last-child {
        border-radius: 0;
      }
    }
  }

  /* Material Card */
  .mat-card {
    border-radius: 0;
    box-shadow: $std-box-shadow !important;
  }

  /* Material Dialog */
  .mat-dialog-container {
    border-radius: 0 !important;
    box-shadow: var(--shadows__modal) !important;

    .mat-dialog-actions {
      justify-content: flex-end;
    }
  }

  /* Material Chip */
  .mat-chip {
    color: var(--colors__white) !important;
    display: inline-block !important;
    padding: 7px 12px;
    border-radius: 24px;
    background-color: $brand;

    &.mat-standard-chip {
      background-color: var(--colors__gray-80);
    }

    &.full-width {
      width: 100%;
    }

    .truncate {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .text-center {
      text-align: center;
    }
  }

  /* Material Select */
  .mat-select-panel {
    &.results-container {
      .mat-option {
        .mat-option-text {
          display: grid;
          grid-template-rows: 1.167rem 1fr;

          .date {
            font-size: 11px;
          }
        }
      }
    }
  }

  /* Material Selection List */
  .mat-selection-list {
    overflow: hidden;

    .mat-list-option {
      &.has-icon {
        .mat-list-text {
          padding-left: 20px !important;
          padding-right: 0px !important;

          .mat-icon {
            position: absolute;
            left: 0px;
            font-size: 24px;
            width: 24px;
            height: 24px;
          }
        }
      }

      &.is-selected {
        background: $brand;

        .mat-pseudo-checkbox {
          display: block;
        }
      }

      .mat-pseudo-checkbox {
        display: none;
      }
    }
  }

  /* Material Table */
  .mat-table {
    .mat-header-row {
      .mat-header-cell {
        padding: 12px 24px;
      }
    }

    .mat-row {
      @extend %hover-bg;

      .mat-cell {
        padding: 12px 24px;
        display: block;
      }
    }
  }

  /* Material Paginator */
  .mat-paginator-container {
    border-top: 1px solid rgba(black, 0.12);
  }

  /* Material Tab Links */
  .mat-tab-links {
    .mat-tab-link {
      font-family: $font-plex-sans;
      font-size: var(--text-sizes__medium);
      color: $secondary-text;

      &.mat-tab-label-active {
        color: $brand;
      }
    }
  }

  /* Material Tooltip */
  .mat-tooltip {
    &.allow-newlines {
      white-space: pre-line;
    }
  }

  /* Material Sidenav & Backdrop */
  .mat-drawer-container.mat-sidenav-container {
    height: 100%;
    background-color: var(--colors__white);

    .mat-drawer-backdrop {
      background: rgba($brand, var(--opacity__25));
      backdrop-filter: blur(3px);
    }
  }

  .cdk-overlay-backdrop {
    background: rgba($brand, var(--opacity__25));
    backdrop-filter: blur(3px);

    &.analyses-toolbar-bottom-sheet-overlay {
      background: initial;
      backdrop-filter: none;
    }
  }

  /* Material slide toggle */
  .mat-slide-toggle {
    font-family: $font-plex-sans;
    font-size: var(--text-sizes__medium);
    color: $secondary-text;

    .mat-slide-toggle-label {
      font-weight: normal;
    }
  }
}
