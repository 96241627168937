@import '~@rms/radius-variables/dist/scss/radius-variables';
@import './miu-variables';

// does not work for accordion displayMode=flat
@mixin spaced-mat-accordion($heightOfClosedPanels) {
  $heightOfHeader: 64px;

  .mat-accordion {
    height: 100%;

    .mat-expansion-panel {
      &.mat-expanded {
        height: calc((100% - #{$heightOfClosedPanels}) - 16px);
        display: flex;
        flex-flow: column nowrap;

        &:first-child,
        &:last-child {
          height: calc(100% - #{$heightOfClosedPanels});
        }

        .mat-expansion-panel-header {
          height: $heightOfHeader;
        }

        .mat-expansion-panel-content {
          height: calc(100% - #{$heightOfHeader});

          .mat-expansion-panel-body {
            height: 100%;
            position: relative;
            overflow: hidden;
          }
        }
      }

      .mat-action-row {
        padding: 8px 16px;
        box-sizing: border-box;
      }
    }
  }
}

@mixin letter-icons($letterIcons...) {
  @each $letterIcon in $letterIcons {
    &.#{$letterIcon}::before {
      font-family: $font-plex-mono;
      font-size: 15px;
      content: $letterIcon;
    }
  }
}

@mixin radius-disabled($color, $background-color) {
  &[disabled][disabled] {
    cursor: not-allowed;
    pointer-events: none;
    color: #{$color};
    background-color: #{$background-color};
  }
}

@mixin radius-button-common($color) {
  @include radius-disabled(white, var(--colors__#{$color}-20));
  color: var(--colors__white);
  background-color: var(--colors__#{$color});
  border: var(--border-widths__large) solid transparent;

  &:hover {
    background-color: var(--colors__#{$color}-40);
  }

  &:active {
    background-color: var(--colors__#{$color}-60);
  }

  .mat-button-focus-overlay {
    opacity: 0;
  }
}

@mixin radius-button-focus($color) {
  &:focus {
    border: var(--border-widths__large) solid var(--colors__#{$color}-70);
  }
}

@mixin radius-icon-button-common($color) {
  @include radius-disabled($disabled-text, transparent);
  color: $standalone-icon;

  &:hover {
    color: var(--colors__#{$color}-40);
  }

  &:active {
    color: var(--colors__#{$color}-60);
  }
}

@mixin radius-snackbar($class, $color) {
  &.#{$class} {
    background-color: var(--colors__#{$color}-10);
    color: var(--colors__#{$color});
    border: var(--border-widths__default) solid var(--colors__#{$color});

    &.has-action {
      background-color: var(--colors__white);

      .type-icon {
        display: flex;
        align-items: center;
        height: 100%;
        width: 42px;
        margin-left: -12px;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        background-color: var(--colors__#{$color}-10);

        .mat-icon {
          margin-left: 12px;
        }
      }
    }

    .type-icon {
      .mat-icon {
        color: var(--colors__#{$color});

        &:not(.#{$class}) {
          display: none;
        }
      }
    }
  }
}

@mixin respond-to($breakpoint) {
  @if map-has-key($screens, $breakpoint) {
    @media (max-width: #{map-get($screens, $breakpoint)}) {
      @content;
    }
  } @else {
    @warn "No value could be retrieved from $screens map with `#{$breakpoint}`.";
  }
}
