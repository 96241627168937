@import './miu-colors';

[hidden],
.hidden {
  display: none !important;
}

.mat-icon {
  svg {
    pointer-events: none;
  }
}

a,
a:hover {
  text-decoration: none;
  color: $primary-text;
}

label {
  display: inline-block;
  max-width: 100%;
  font-weight: 700;
}

.selected {
  background-color: $selected-bg;

  input {
    background-color: $selected-bg;
  }
}

.success {
  color: $success;
}

.warn {
  color: $warning;
}

.danger {
  color: $danger;
}

.never-show-checkbox {
  height: 40px;
}

html,
body {
  height: 100%;
  margin: 0;

  input {
    &:disabled {
      cursor: not-allowed;
    }
  }

  :focus {
    outline: none;
  }
}

body {
  background-color: $nav-bg;
  position: relative;

  #wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;

    #page-wrapper {
      min-height: 100%;
      margin: 0 $page-gutter 0 0;
      background-color: var(--colors__white);
      position: inherit;
      display: flex;
      flex-direction: row;

      &.no-margin {
        margin: 0;
      }

      #subnav {
        display: flex;
        flex-flow: column nowrap;
        margin: $header-height 5px 0 5px;
        padding: 0;
        width: $page-gutter;
        min-width: $page-gutter;
      }

      #content-wrapper {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
        overflow-x: hidden;

        #header-wrapper {
          display: flex;
          align-items: center;
          background-color: var(--colors__white);

          .hamburger {
            position: absolute;
            left: 5px;
          }
        }

        #body-wrapper {
          display: flex;
          flex-flow: column nowrap;
          flex: 1 1 100%;
        }
      }
    }
  }
}
