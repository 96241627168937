$std-height: 32px;
$std-icon-font-size: 24px;
$font-plex-sans: var(--fonts__ibm-plex-sans), sans-serif;
$font-plex-mono: var(--fonts__ibm-plex-mono), mono;
$font-inter: var(--fonts__inter), sans-serif;

$page-gutter: 40px;
$subnav-h-margin: 5px;
$sidebar-width: $page-gutter + 2 * $subnav-h-margin;
$header-height: 70px;
$content-padding-bottom: 30px;
$content-height: calc(100vh - (#{$header-height} + #{$content-padding-bottom}));
$std-box-shadow: var(--shadows__elevation-1);
$margin-for-box-shadow: 10px;

// plugin overrides
// MaterialDesignIcons
$mdi-font-path: '~@mdi/font/fonts';
