@import '~@rms/radius-variables/dist/scss/radius-variables';

$brand: map-get($colors, rms-blue);
$success: map-get($colors, grizzly-green);
$warning: map-get($colors, autumn-amber);
$danger: map-get($colors, ruby-red);
$border-color: map-get($colors, gray-10);
$white: map-get($colors, white);

$nav-bg: map-get($colors, white);
$nav-bg-active: map-get($colors, rms-blue-10);

$selected-bg: map-get($colors, rms-blue-20);
$hover-bg: map-get($colors, rms-blue-10);

$primary-text: map-get($colors, gray-70);
$secondary-text: map-get($colors, gray);
$tertiary-text: map-get($colors, gray-40);
$disabled-text: map-get($colors, gray-20);
$interactive-text: map-get($colors, rms-blue);

$dark-primary-text: rgba(black, 0.87);

$standalone-icon: map-get($colors, gray);
$mat-primary: (
  50: #e9f2ff,
  100: #c8ddff,
  200: #a3c7ff,
  300: #7eb1ff,
  400: #62a0ff,
  500: #468fff,
  600: #3f87ff,
  700: #377cff,
  800: #2f72ff,
  900: #2060ff,
  A100: #ffffff,
  A200: #ffffff,
  A400: #cfdcff,
  A700: #b6c9ff,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$mat-accent: (
  50: #e9f2ff,
  100: map-get($colors, rms-blue-10),
  200: map-get($colors, rms-blue-20),
  300: map-get($colors, rms-blue-30),
  400: map-get($colors, rms-blue-40),
  500: map-get($colors, rms-blue),
  600: map-get($colors, rms-blue-60),
  700: map-get($colors, rms-blue-70),
  800: map-get($colors, rms-blue-80),
  900: map-get($colors, rms-blue-90),
  A100: #ffffff,
  A200: #ffffff,
  A400: #cfdcff,
  A700: #b6c9ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$mat-warn: (
  50: #fbe7ea,
  100: map-get($colors, ruby-red-10),
  200: map-get($colors, ruby-red-20),
  300: map-get($colors, ruby-red-30),
  400: map-get($colors, ruby-red-40),
  500: map-get($colors, ruby-red),
  600: map-get($colors, ruby-red-60),
  700: map-get($colors, ruby-red-70),
  800: map-get($colors, ruby-red-80),
  900: map-get($colors, ruby-red-90),
  A100: #fff6f7,
  A200: #ffc3c8,
  A400: #ff909a,
  A700: #ff7782,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);
