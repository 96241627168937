@import './miu-variables';
@import './miu-colors';
@import './miu-mixins';

%ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

%fixed-head-table {
  display: flex;
  flex-direction: column;

  thead,
  tbody {
    display: block;
  }

  thead > tr {
    overflow: hidden;
    margin-right: 15px; // account for scrollbar so header cells will align with body cells
  }

  tbody {
    flex: 1 1 0%;
    overflow-y: scroll;
  }

  tr {
    display: flex;
    align-items: center;

    td,
    th {
      flex: 1 1 0%; // IE11 will break if last param is used without a unit
      display: block; // Required for IE11 to make cells expand to full width and prevent overflow in table cells
      height: $std-height; // Required to ensure blank headings line up correctly
    }
  }
}

%selectable {
  &.selected {
    background-color: $selected-bg;
  }
}

%disabled {
  &[disabled],
  &.disabled {
    cursor: not-allowed !important;
    pointer-events: none !important;
    color: $disabled-text !important;

    .mat-icon {
      color: currentColor !important;
    }

    &:hover {
      color: currentColor !important;

      .mat-icon {
        color: currentColor !important;
      }
    }
  }
}

%hover {
  @extend %disabled;
  background-color: transparent;
  cursor: pointer;

  &:hover:not([disabled]) {
    color: $brand;
  }

  &:active:not([disabled]) {
    color: $dark-primary-text;
  }
}

%hover-bg {
  @extend %disabled;
  cursor: pointer;

  &:hover:not([disabled]) {
    background-color: $hover-bg !important;

    input {
      background-color: $hover-bg;
    }

    .mat-icon {
      color: $brand !important;
    }

    .mat-mini-fab {
      .mat-icon {
        color: currentColor !important;
      }
    }
  }

  &:active:not([disabled]) {
    color: $dark-primary-text;
  }
}

%flex-column {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}

%faux-mat-error {
  position: absolute;
  font-size: 10.5px;
  font-weight: 400;
  color: $warning;
}

%radius-button {
  height: 42px;
  text-transform: uppercase;
  border-radius: 0;

  .mat-icon {
    margin: 0px 8px 0px -4px;
  }
}

%radius-primary-fab {
  @include radius-button-common(rms-blue);

  .mat-button-wrapper {
    padding: 0;
  }
}

%radius-primary-button {
  @include radius-button-common(rms-blue);
  @include radius-button-focus(rms-blue);
}

%radius-secondary-button {
  @include radius-button-common(gray);
  @include radius-button-focus(gray);
}

%radius-danger-button {
  @include radius-button-common(ruby-red);
  @include radius-button-focus(ruby-red);
}

%radius-plain-button {
  background-color: var(--colors__white);
  color: $primary-text;
  border: none;

  &[disabled][disabled] {
    cursor: not-allowed;
    pointer-events: none;
    color: white;
    background-color: $disabled-text;
  }

  &:hover {
    background-color: var(--colors__white);
  }

  &:active {
    background-color: var(--colors__white);
  }
}
