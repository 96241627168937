@import 'miu-variables';

.b-content {
  display: block;
  position: relative;
  color: $dark-primary-text;
  padding-bottom: $content-padding-bottom;

  .b-content__page {
    overflow: auto;
    max-height: $content-height;
  }
}
