@use '@angular/material' as mat;
@import '../assets/Inter/inter.css';

$custom-typography: mat.define-typography-config(
  $font-family: var(--text-styles__body),
  $display-4:
    mat.define-typography-level(
      112px,
      112px,
      300,
      $font-family: var(--text-styles__heading-font),
      $letter-spacing: -0.05em
    ),
  $display-3:
    mat.define-typography-level(
      56px,
      56px,
      400,
      $font-family: var(--text-styles__heading-font),
      $letter-spacing: -0.02em
    ),
  $display-2:
    mat.define-typography-level(
      45px,
      48px,
      400,
      $font-family: var(--text-styles__heading-font),
      $letter-spacing: -0.005em
    ),
  $display-1: mat.define-typography-level(34px, 40px, 400, $font-family: var(--text-styles__heading-font)),
  $headline: mat.define-typography-level(24px, 32px, 400, $font-family: var(--text-styles__heading-font)),
  $title: mat.define-typography-level(20px, 32px, 500, $font-family: var(--text-styles__heading-font)),
  $subheading-2:
    mat.define-typography-level(16px, 28px, 400, $font-family: var(--text-styles__heading-font)),
  $subheading-1:
    mat.define-typography-level(15px, 24px, 400, $font-family: var(--text-styles__heading-font)),
  $button: mat.define-typography-level(14px, 14px, 500, $font-family: var(--text-styles__heading-font)),
  $input: mat.define-typography-level(inherit, 1.125, 400, $font-family: var(--text-styles__body)),
  $body-2: mat.define-typography-level(14px, 24px, 500, $font-family: var(--text-styles__body)),
  $body-1: mat.define-typography-level(14px, 20px, 400, $font-family: var(--text-styles__body)),
  $caption: mat.define-typography-level(12px, 20px, 400, $font-family: var(--text-styles__body))
);

@include mat.all-component-typographies($custom-typography);

p {
  margin: var(--spacing__regular) 0;
}
